'use client';
import ErrorLayout, { ErrorType } from '@/components/common/ErrorLayout';
import React from 'react';

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}): React.JSX.Element {
  console.error(error);
  return <ErrorLayout type={ErrorType.GLOBAL} countdown={20} error={error} reset={reset} />;
}
